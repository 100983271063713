import React from 'react';
import { NavLink } from 'react-router-dom';
import './MainMenu.scss';

const MainMenu = (props) => {
  const menuItems = props.items.map((item) => (
    <li key={item.name} className='MainMenu__item'>
      <NavLink exact activeClassName='MainMenu__item_active' to={item.link}>
        {item.name}
      </NavLink>
    </li>
  ));
  return <ul className='MainMenu'>{menuItems}</ul>;
};

export default MainMenu;
