import React from 'react';
import './Fullpage.scss';

const Fullpage = (props) => {
  const classNames = ['Fullpage', props.className];

  if (props.active) classNames.push('Fullpage_active');
  return (
    <div className={classNames.join(' ')} style={{ background: props.color }}>
      {props.children}
    </div>
  );
};

export default Fullpage;
