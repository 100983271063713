import * as actionTypes from './actionTypes';
import axios from 'axios';

const fetchNewsStart = () => {
  return {
    type: actionTypes.FETCH_NEWS_START,
  };
};

const fetchNewsSuccess = (actionType, newsObj) => {
  return {
    type: actionType,
    ...newsObj,
  };
};

export const fetchNews = (type = 'set', page = 1) => {
  const pageNum = Number.isInteger(parseInt(page)) ? page : 1;
  console.log(pageNum);
  return (dispatch) => {
    dispatch(fetchNewsStart());
    axios.get('/get-news?page=' + pageNum).then((response) => {
      const news = response.data.news.map((newsItem) => {
        return {
          id: newsItem.id,
          created: newsItem.created,
          createdVisible: newsItem.created_visible,
          name: newsItem.name,
          slug: newsItem.slug,
          image: newsItem.image,
          description: newsItem.description,
          body: newsItem.body,
        };
      });
      const newsObject = {
        previousPage: parseInt(response.data.previous_page),
        nextPage: parseInt(response.data.next_page),
        currentPage: parseInt(response.data.current_page),
        pagesCount: parseInt(response.data.pages_count),
        news: news,
      };
      const actionType = type === 'add' ? actionTypes.ADD_NEWS : actionTypes.SET_NEWS;
      dispatch(fetchNewsSuccess(actionType, newsObject));
    });
  };
};

export const setNewsDetail = (detail) => {
  return {
    type: actionTypes.SET_NEWS_DETAIL,
    detail,
  };
};

export const fetchNewsDetailStart = () => {
  return {
    type: actionTypes.FETCH_NEWS_DETAIL_START,
  };
};

export const fetchNewsDetailNotFound = () => {
  return {
    type: actionTypes.FETCH_NEWS_DETAIL_NOT_FOUND,
  };
};

export const fetchNewsDetail = (slug) => {
  return (dispatch) => {
    dispatch(fetchNewsDetailStart());
    axios.get('/get-news/' + slug).then((response) => {
      if (response.data.error) {
        return dispatch(fetchNewsDetailNotFound());
      }

      const responseArticle = response.data.article;
      const responseOthers = response.data.other_news;
      const others = responseOthers.map((item) => ({
        id: item.id,
        created: item.created,
        createdVisible: item.created_visible,
        name: item.name,
        slug: item.slug,
        image: item.image,
        description: item.description,
        body: item.body,
        others: responseOthers,
      }));

      const detail = {
        article: {
          id: responseArticle.id,
          created: responseArticle.created,
          createdVisible: responseArticle.created_visible,
          name: responseArticle.name,
          slug: slug,
          image: responseArticle.image,
          description: responseArticle.description,
          body: responseArticle.body,
        },
        others: others,
      };

      dispatch(setNewsDetail(detail));
    });
  };
};
