import React from 'react';
import PropTypes from 'prop-types';

import './MapPoint.scss';

const MapPoint = (props) => {
  const styles = {
    ...props.style,
    position: props.absolute ? 'absolute' : 'relative',
    width: props.width,
    height: 0,
    paddingBottom: props.width,
    top: props.top,
    left: props.left,
    zIndex: 3,
  };
  const classes = ['MapPoint'];
  const icon = props.icon ? (
    <div className='MapPoint__icon'>
      <img src={props.icon} alt='' />
    </div>
  ) : null;

  const text = props.children ? (
    <div className='MapPoint__text-container'>
      <div className='MapPoint__text'>{props.children}</div>
    </div>
  ) : null;

  const pulse = props.pulse ? (
    <div className='MapPoint__pulse' style={{ animationDelay: props.style.animationDelay }}></div>
  ) : null;

  if (props.children) classes.push('MapPoint_hastext');
  if (props.yellow) classes.push('MapPoint_yellow');
  if (props.pulse) classes.push('MapPoint_pulse');

  return (
    <div className={classes.join(' ')} style={styles}>
      {pulse}
      <div className='MapPoint__angle'>
        <svg viewBox='0 0 30 25' xmlns='http://www.w3.org/2000/svg'>
          <path d='M14.9109 24.3643L0.699888 0.16871L29.1219 0.168712L14.9109 24.3643Z' />
        </svg>
      </div>
      {text}
      <div className='MapPoint__circle' style={{ width: '100%', height: '100%' }}>
        <div className='MapPoint__inner'>{icon}</div>
      </div>
    </div>
  );
};

MapPoint.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  absolute: PropTypes.bool,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pulse: PropTypes.bool,
};

MapPoint.defaultProps = {
  width: 47,
  icon: false,
  pulse: false,
  absolute: false,
  left: 0,
  top: 0,
};

export default MapPoint;
