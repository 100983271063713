import React from 'react';

import MainMenu from '../MainMenu/MainMenu';
import Button from '../UI/Button/Button';
import LangSwitcher from '../UI/LangSwitcher/LangSwitcher';
import SandwichMenu from '../SandwichMenu/SandwichMenu';

import './Header.scss';
import logo from '../../assets/images/common/logo.svg';

import * as actionTypes from '../../store/actions/actionTypes';
import * as lang from './lang';
import { connect } from 'react-redux';

const Header = (props) => {
  return (
    <header className='Header'>
      <div className='content'>
        <div className='row align-items-center'>
          <div className='col-6 col-md-4 col-lg-2'>
            <a className='w-100' href='/'>
              <img className='Header__logo' src={logo} alt='Bestplace' />
            </a>
          </div>
          <div className='col-2 offset-4 d-none d-lg-block'>
            <MainMenu items={props.menu} />
          </div>
          <div className='col-2 d-none d-lg-block'>
            <Button clicked={() => props.openPopup('callback')} theme='gray'>
              {lang.CallbackButtonTitle}
            </Button>
          </div>
          <div className='col-1 d-none d-lg-block'>
            <LangSwitcher />
          </div>
          <div className='col-lg-1 col-6'>
            <SandwichMenu menuItems={props.burgerMenu} />
          </div>
        </div>
      </div>
    </header>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (name) => dispatch({ type: actionTypes.OPEN_MODAL, modal: name }),
  };
};

export default connect(null, mapDispatchToProps)(Header);
