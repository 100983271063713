import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './Modal.scss';
const Modal = (props) => {
  const domElem = useRef();
  const markup = (
    <CSSTransition
      in={props.show}
      className='Modal'
      classNames='modal-transition'
      timeout={300}
      appear
      unmountOnExit
      nodeRef={domElem}
    >
      <div className='Modal' ref={domElem}>
        <div className='Modal__overlay' onClick={props.closed}></div>
        <div className='Modal__content'>
          <div className='Modal__close-container'>
            <div className='Modal__close' onClick={props.closed}>
              <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect x='2.78516' width='35.46' height='3.94' rx='1.97' transform='rotate(45 2.78516 0)' fill='#5F7391' />
                <rect
                  width='35.46'
                  height='3.94'
                  rx='1.97'
                  transform='matrix(-0.707107 0.707107 0.707107 0.707107 25.2148 0)'
                  fill='#5F7391'
                />
              </svg>
            </div>
          </div>
          <div className='Modal__content-inner'>{props.children}</div>
        </div>
      </div>
    </CSSTransition>
  );

  return createPortal(markup, document.body);
};

export default Modal;
