import { Children, useRef, useState, cloneElement, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Fullpage from '../../components/Fullpage/Fullpage';

import './FullpageWrapper.scss';

const FullpageWrapper = (props) => {
  const [activeItem, setActiveItem] = useState(1);
  const [minScrolling, setMinScrolling] = useState(0);
  const [maxScrolling, setMaxScrolling] = useState(0);

  const childrenCount = Children.toArray(Children.toArray(props.children)[1].props.children).filter(
    (child) => child.type === Fullpage
  ).length;

  const scrollHeight = window.innerHeight;

  const renderBackground = () => {
    const children = Children.map(props.children, (child, index) => {
      if (child.type !== Fullpage) {
        return child;
      }
    });
    return children;
  };

  const dotClickedHandler = (itemNum) => {
    scrollToItem(itemNum);
  };

  const renderDots = () => {
    const dots = [];
    for (let i = 1; i <= childrenCount; i++) {
      const classes = ['FullpageWrapper__dot'];
      if (i === activeItem) classes.push('FullpageWrapper__dot_active');
      dots.push(<div key={i} className={classes.join(' ')} onClick={() => dotClickedHandler(i)}></div>);
    }
    return dots;
  };

  const renderChildren = () => {
    const children = Children.toArray(Children.toArray(props.children)[1].props.children)
      .filter((item) => item.type === Fullpage)
      .map((child, index) => cloneElement(child, { number: index + 1, active: index + 1 === activeItem, key: index + 1 }));
    return children.filter((item) => item.props.active);
    // return children;
  };

  const scrollToItem = (item) => {
    let itemPosition = item * window.innerHeight + minScrolling;
    canScroll.current = false;

    if (item === 1) {
      itemPosition = 0;
    }

    setTimeout(() => {
      canScroll.current = true;
    }, 1200);

    window.scrollTo({ top: itemPosition, behavior: 'smooth' });
  };

  const innerContainer = useRef();
  const compContainer = useRef();
  const scrollPosition = useRef(0);
  const canScroll = useRef(true);

  const setScrollDirection = (direction) => {
    compContainer.current.classList.remove('fullpage-animation-top');
    compContainer.current.classList.remove('fullpage-animation-bottom');
    compContainer.current.classList.add(`fullpage-animation-${direction}`);
  };

  useEffect(() => {
    const scrollHandler = (e) => {
      const position = Math.abs(compContainer.current.getBoundingClientRect().top);
      scrollPosition.current = position;

      if (position >= 0 && position < maxScrolling) {
        let activeItemNum = Math.ceil(position / window.innerHeight);
        if (activeItemNum === 0) activeItemNum = 1;

        if (activeItemNum !== activeItem) {
          setActiveItem((prevItem) => {
            if (activeItemNum > prevItem) {
              setScrollDirection('bottom');
            } else {
              setScrollDirection('top');
            }
            return activeItemNum;
          });
        }
      }
    };

    const wheelHandler = (e) => {
      if (scrollPosition.current >= 0 && scrollPosition.current < maxScrolling) {
        e.preventDefault();
        const deltaY = e.deltaY;

        if (!canScroll.current) return false;

        if (Math.abs(deltaY) > 5) {
          if (deltaY > 0 && activeItem < childrenCount) {
            scrollToItem(activeItem + 1);
          }

          if (deltaY < 0 && activeItem > 1) {
            scrollToItem(activeItem - 1);
          }
        }
      }
    };

    window.addEventListener('wheel', wheelHandler, { passive: false });
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('wheel', wheelHandler);
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [maxScrolling, activeItem]);

  useEffect(() => {
    const minScrolling = compContainer.current.getBoundingClientRect().top;
    setMinScrolling(minScrolling + window.scrollY);
    setMaxScrolling(minScrolling + window.innerHeight * childrenCount);
  }, [childrenCount]);

  return (
    <div
      className='FullpageWrapper fullpage-animation-bottom'
      style={{ height: `calc((100vh * ${childrenCount} + ${scrollHeight}px)` }}
      ref={compContainer}
    >
      <div className='FullpageWrapper__background-container'>
        <div className='FullpageWrapper__background'>{renderBackground()}</div>
      </div>
      <div className='FullpageWrapper__dots-container'>
        <div className='FullpageWrapper__dots'>{renderDots()}</div>
      </div>
      <SwitchTransition>
        <CSSTransition
          appear={true}
          // onExit={this.setScrollDirection}
          in={true}
          key={activeItem}
          timeout={600}
          nodeRef={innerContainer}
          classNames='fullpage-animation'
        >
          <div ref={innerContainer} className={'FullpageWrapper__inner '}>
            {renderChildren()}
            <div className='FullpageWrapper__scroll-icon'>
              <div className='scroll-icon'>
                <div className='scroll-icon__mouse'>
                  <div className='scroll-icon__wheel'></div>
                </div>
                <div className='scroll-icon__arrow'></div>
                <div className='scroll-icon__arrow'></div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default FullpageWrapper;
