import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import './LastCase.scss';

const LastCase = ({ match, name, mainImage, mainVideo, description, slug }) => {
  const lang = match.params.lang;
  return (
    <div className='LastCase'>
      <div className='row align-items-center'>
        <div className='col-12 col-lg-5 offset-lg-1'>
          {/* <Link to={'/' + lang + '/cases/' + slug}> */}
          <div className='LastCase__image' style={{ backgroundImage: `url(${mainImage})` }}>
            <iframe
              className='LastCase__video'
              width='560'
              height='315'
              src={`https://www.youtube.com/embed/${mainVideo}`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
          {/* </Link> */}
        </div>
        <div className='col-12 col-lg-5'>
          <Link to={'/' + lang + '/cases/' + slug}>
            <h3 className='LastCase__name'>{name}</h3>
          </Link>
          <p className='paragraph LastCase__description'>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LastCase);
