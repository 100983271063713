import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import ScheduleForm from '../../forms/ScheduleForm/ScheduleForm';
import './Footer.scss';

import MainMenu from '../MainMenu/MainMenu';
import Button from '../UI/Button/Button';
import LangSwitcher from '../UI/LangSwitcher/LangSwitcher';

import Skolkovo from '../../assets/images/common/skolkovo.svg';
import Qlab from '../../assets/images/common/q-lab.svg';

import * as actionTypes from '../../store/actions/actionTypes';
import * as lang from './lang';
import { connect } from 'react-redux';

const Footer = (props) => {
  const menu = props.menu.map((menuItem) => {
    return (
      <div key={menuItem.link} className='col-lg-6 col-12 Footer__link'>
        <NavLink to={menuItem.link} exact>
          {menuItem.name}
        </NavLink>
      </div>
    );
  });
  return (
    <footer className='Footer' ref={props.propRef} style={props.style}>
      <div className='Footer__inner' ref={props.inner}>
        <ScheduleForm loaded={props.loaded} />
        <div className='Footer-bottom' ref={props.bottom}>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-4 Footer__privacy'>
                <p className='Footer-bottom__agreement'>{lang.FooterAgreementText}</p>
                <Link className='Footer-bottom__privacy' to='/privacy-policy'>
                  {lang.PrivacyPolicy}
                </Link>
              </div>
              <div className='Footer__menu col-12 col-lg-4 offset-lg-1'>
                <div className='row'>
                  <div className='col-6 col-lg-12'>
                    <div className='row'>{menu}</div>
                  </div>
                  <div className='col-3 offset-3 d-lg-none'>
                    <LangSwitcher />
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-12'>
                <div className='row'>
                  <div className='col-lg-8 col-12 Footer__button-column'>
                    <Button clicked={() => props.openPopup('callback')} theme='gray'>
                      {lang.CallbackButtonTitle}
                    </Button>

                    <a className='Footer__contact' href='mailto:box@bestplace.ai'>
                      box@bestplace.ai
                    </a>
                    {/* <a
                      target='_blank'
                      rel='noreferrer'
                      className='Footer__contact'
                      href='https://www.linkedin.com/company/14038853/'
                    >
                      linkedin
                    </a> */}
                  </div>
                  <div className='col-lg-4 d-none d-lg-block'>
                    <LangSwitcher />
                  </div>
                </div>
              </div>
            </div>
            <div className='Footer-bottom__bottom'>
              <div className='row'>
                <div className='Footer__copyright-col col-12 col-lg-4'>
                  <div className='Footer-bottom__copy'>
                    © {new Date().getFullYear()} BestPlace. {lang.AllRightsReserved}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (name) => dispatch({ type: actionTypes.OPEN_MODAL, modal: name }),
  };
};

export default connect(null, mapDispatchToProps)(Footer);
