import * as actionTypes from './actionTypes';
import axios from 'axios';

const fetchCasesStart = () => {
  return {
    type: actionTypes.FETCH_NEWS_START,
  };
};

const fetchCasesSuccess = (actionType, newsObj) => {
  return {
    type: actionType,
    ...newsObj,
  };
};

export const fetchCases = (type = 'set', page = 1) => {
  const pageNum = Number.isInteger(parseInt(page)) ? page : 1;
  return (dispatch) => {
    dispatch(fetchCasesStart());
    axios.get('/get-cases?page=' + pageNum).then((response) => {
      const cases = response.data.cases.map((caseItem) => {
        return {
          id: caseItem.id,
          created: caseItem.created,
          createdVisible: caseItem.created_visible,
          name: caseItem.name,
          companyName: caseItem.company_name,
          slug: caseItem.slug,
          logo: caseItem.logo,
          description: caseItem.description,
          body: caseItem.body,
        };
      });

      const casesObject = {
        previousPage: parseInt(response.data.previous_page),
        nextPage: parseInt(response.data.next_page),
        currentPage: parseInt(response.data.current_page),
        pagesCount: parseInt(response.data.pages_count),
        cases: cases,
      };
      const actionType = type === 'add' ? actionTypes.ADD_CASES : actionTypes.SET_CASES;
      dispatch(fetchCasesSuccess(actionType, casesObject));
    });
  };
};

export const setCasesDetail = (detail) => {
  return {
    type: actionTypes.SET_CASES_DETAIL,
    detail,
  };
};

export const fetchCasesDetailStart = () => {
  return {
    type: actionTypes.FETCH_CASES_DETAIL_START,
  };
};

export const fetchCasesDetailNotFound = () => {
  return {
    type: actionTypes.FETCH_CASES_DETAIL_NOT_FOUND,
  };
};

export const fetchCasesDetail = (slug) => {
  return (dispatch) => {
    dispatch(fetchCasesDetailStart());
    axios
      .get('/get-cases/' + slug)
      .then((response) => {
        if (response.data.error) {
          return dispatch(fetchCasesDetailNotFound());
        }
        const responseArticle = response.data.article;
        const responseOthers = response.data.other_cases ? response.data.other_cases : [];
        const others = responseOthers.map((item) => ({
          id: item.id,
          created: item.created,
          createdVisible: item.created_visible,
          name: item.name,
          slug: item.slug,
          logo: item.logo,
          description: item.description,
          companyName: item.company_name,
          body: item.body,
          others: responseOthers,
        }));

        const detail = {
          article: {
            id: responseArticle.id,
            created: responseArticle.created,
            createdVisible: responseArticle.created_visible,
            name: responseArticle.name,
            slug: slug,
            logo: responseArticle.logo,
            companyName: responseArticle.company_name,
            objective: responseArticle.objective,
            ideas: responseArticle.ideas,
            description: responseArticle.description,
            body: responseArticle.body,
          },
          others: others,
        };

        dispatch(setCasesDetail(detail));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchLastCaseSuccess = (lastCase) => {
  return {
    type: actionTypes.FETCH_LAST_CASE_SUCCESS,
    lastCase,
  };
};

export const fetchLastCase = () => {
  return (dispatch) => {
    axios.get('/get-cases/last/').then((response) => {
      const responseArticle = response.data.article ? response.data.article : {};
      const lastArticle = {
        name: responseArticle.name,
        slug: responseArticle.slug,
        mainImage: responseArticle.main_image,
        mainVideo: parseYoutubeId(responseArticle.main_video),
        description: responseArticle.description,
      };

      dispatch(fetchLastCaseSuccess(lastArticle));
    });
  };
};

function parseYoutubeId(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}
