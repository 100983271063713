import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import CaseItem from './CaseItem/CaseItem';
import Pagination from '../../components/UI/Pagination/Pagination';

import * as lang from '../../Pages/Cases/lang';

import './CasesList.scss';

const CasesList = (props) => {
  const fetchCases = props.fetchCases;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');

    const handlePopState = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const page = urlParams.get('page');
      fetchCases(page);
    };

    window.addEventListener('popstate', handlePopState);

    fetchCases(page);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [fetchCases]);

  const caseItems = props.cases.map((item) => <CaseItem {...item} key={item.id} />);

  const loadMore = (e) => {
    e.preventDefault();
    const nextPage = props.currentPage + 1;
    fetchCases(nextPage, 'add');
    props.history.push('?page=' + nextPage);
  };

  const loadMoreBtn = props.nextPage ? (
    <button onClick={loadMore} className='NewsList__loadmore'>
      {lang.CasesListLoadMoreButton}
    </button>
  ) : null;

  const setPage = (page) => {
    fetchCases(page);
    props.history.push('?page=' + page);
  };

  const pagination =
    props.pagesCount > 1 ? (
      <Pagination currentPage={props.currentPage} pagesCount={props.pagesCount} onSetPage={setPage} />
    ) : null;

  const navigation =
    pagination || loadMoreBtn ? (
      <div className='NewsList__navigation'>
        <div className='row'>
          <div className='col-2 offset-5'>{loadMoreBtn}</div>
          <div className='col-3 offset-2'>{pagination}</div>
        </div>
      </div>
    ) : null;

  return (
    <div className='CasesList'>
      <div className='content'>
        <div className='NewsList__container CasesList__container'>
          <div className='row CasesList__row'>{caseItems}</div>
        </div>
        {navigation}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.cases,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCases: (page = 1, type = 'set') => dispatch(actions.fetchCases(type, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CasesList));
