import React from 'react';

import './Pagination.scss';

const Pagination = ({ currentPage, onSetPage, pagesCount }) => {
  const pages = [];

  for (let pageNumber = 1; pageNumber <= pagesCount; pageNumber++) {
    if (Math.abs(pageNumber - currentPage) < 3 || pageNumber === pagesCount || pageNumber === 1) {
      pages.push({
        text: pageNumber,
        value: pageNumber,
      });
    } else if (pageNumber === 2 || pageNumber === pagesCount - 1) {
      pages.push({
        text: '...',
        value: pageNumber,
      });
    }
  }

  const handlePageClick = (e, page) => {
    e.preventDefault();
    onSetPage(page);
  };

  const handleArrowClick = (e, type) => {
    e.preventDefault();
    let page = false;
    if (type === 'prev' && currentPage > 1) {
      page = currentPage - 1;
    } else if (type === 'next' && currentPage < pagesCount) {
      page = currentPage + 1;
    }

    if (page) onSetPage(page);
  };

  const pagesMarkdown = pages.map((page) => {
    const classes = ['Pagination__item'];
    if (page.value === currentPage) classes.push('Pagination__item_active');
    return (
      <li className={classes.join(' ')} key={page.value} onClick={(e) => handlePageClick(e, page.value)}>
        {page.text}
      </li>
    );
  });
  return (
    <div className='Pagination'>
      <div className='Pagination__arrow Pagination__arrow_prev' onClick={(e) => handleArrowClick(e, 'prev')}>
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M8.00003 13L2 7L8.00003 0.999972' strokeWidth='2' />
        </svg>
      </div>
      <ul className='Pagination__list'>{pagesMarkdown}</ul>
      <div className='Pagination__arrow Pagination__arrow_next' onClick={(e) => handleArrowClick(e, 'next')}>
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M5.99997 13L12 7L5.99997 0.999972' strokeWidth='2' />
        </svg>
      </div>
    </div>
  );
};

export default Pagination;
