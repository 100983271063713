import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';

import * as actions from '../../store/actions/index';
import * as langGetters from '../../Pages/News/lang';

import './NewsDetail.scss';
import NewsItem from '../../containers/NewsList/NewsItem/NewsItem';
import NotFound from '../NotFound/NotFound';

const NewsDetail = (props) => {
  const fetchDetail = props.fetchDetail;
  const slug = props.match.params.slug;
  const lang = props.match.params.lang;

  useEffect(() => {
    fetchDetail(slug);
  }, [fetchDetail, slug]);

  const article = props.article;
  const notFound = props.notFound;
  const otherNews = props.others ? props.others.map((item) => <NewsItem key={item.id} {...item} />) : null;

  let articleMarkdown = article ? (
    <div className='NewsDetail'>
      <div className='News-header'>
        <div className='content'>
          <Link to={`/${lang}/blog`}>
            <div className='News-header__back'>
              <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M6.00003 0.999972L2 5L6.00003 9.00003' stroke='#017BE5' strokeWidth='2' />
              </svg>
              {langGetters.BlogDetailBackButton}
            </div>
          </Link>
          <div className='row'>
            <div className='col-12 col-lg-5'>
              <div className='NewsDetail__image' style={{ backgroundImage: `url(${article.image})` }}></div>
              <p className='NewsDetail__description d-lg-none'>{article.description}</p>
            </div>

            <div className='News-header__name-column col-12 col-lg-6 offset-lg-1'>
              <div className='NewsDetail__date'>{moment(article.createdVisible).format('D MMMM YYYY')}</div>
              <h1 className='caption caption_1'>{article.name}</h1>
              <p className='NewsDetail__description d-none d-lg-block'>{article.description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='NewsDetail__body article-body'>
        <div className='content'>
          <div className='row'>
            <div className='col-12 col-lg-8 offset-lg-2'>{parse(article.body)}</div>
          </div>
        </div>
      </div>
      <div className='NewsDetail__others article-others'>
        <div className='content'>
          <div className='row align-items-center'>
            <div className='col-12 col-lg-6'>
              <h2 className='caption caption_1'>{langGetters.BlogDetailOtherPublications}</h2>
            </div>
            <div className='col-3 offset-3 d-none d-lg-block'>
              <Link to={`/${lang}/blog`}>
                <div className='News-header__back News-header__back_right'>
                  {langGetters.BlogDetailAllPublications}
                  <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M0.999972 0.999972L5 5L0.999972 9.00003' stroke='#017BE5' strokeWidth='2' />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
          <div className='row'>{otherNews}</div>
          <div className='col-12 d-lg-none'>
            <Link to={`/${lang}/blog`}>
              <div className='News-header__back News-header__back_right'>
                {langGetters.BlogDetailAllPublications}
                <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M0.999972 0.999972L5 5L0.999972 9.00003' stroke='#017BE5' strokeWidth='2' />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  if (notFound) {
    articleMarkdown = <NotFound />;
  }

  return articleMarkdown;
};

const mapStateToProps = (state) => ({
  ...state.news.detail,
  notFound: state.news.notFound,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDetail: (slug) => dispatch(actions.fetchNewsDetail(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail);
