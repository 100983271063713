import * as actionTypes from '../actions/actionTypes';
import getCookie from '../../assets/js/getCookie';

const defaultLanguage = {
  text: 'en',
  value: 'en',
};

const supportedLangs = [
  {
    text: 'en',
    value: 'en',
  },
  // {
  //   text: 'ru',
  //   value: 'ru',
  // },
  {
    text: 'pl',
    value: 'pl',
  },
];

let currentLanguage = supportedLangs.find((lang) => lang.value === getCookie('SITE_LANG'));

if (!currentLanguage) currentLanguage = defaultLanguage;

const initialState = {
  active: currentLanguage,
  items: supportedLangs,
};

const language = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        active: action.activeLanguage,
      };
    default:
      return state;
  }
};

export default language;
