import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import LangSwitcher from '../UI/LangSwitcher/LangSwitcher';
import Button from '../UI/Button/Button';

import * as actionTypes from '../../store/actions/actionTypes';
import * as lang from './lang';

import './SandwichMenu.scss';

const SandwichMenu = (props) => {
  const [isOpen, setOpened] = useState(false);
  const sandwichClasses = ['sandwich'];

  if (isOpen) sandwichClasses.push('sandwich_opened');

  const nodeMenu = useRef();

  const onClickSandwich = (e) => {
    e.preventDefault();
    if (!isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
    setOpened(!isOpen);
  };

  const onClickNavLink = (e) => {
    if (!isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
    setOpened(false);
  };

  const menuItems = props.menuItems.map((menuItem) => {
    return (
      <li key={menuItem.link} className='sandwich-menu__link'>
        <NavLink onClick={onClickNavLink} exact to={menuItem.link}>
          {menuItem.name}
        </NavLink>
      </li>
    );
  });

  return (
    <div className='SandwichMenu'>
      <div onClick={onClickSandwich} className={sandwichClasses.join(' ')}>
        <div className='sandwich__line sandwich__line_top'></div>
        <div className='sandwich__line sandwich__line_middle'></div>
        <div className='sandwich__line sandwich__line_bottom'></div>
      </div>
      <CSSTransition in={isOpen} timeout={300} nodeRef={nodeMenu}>
        <div ref={nodeMenu} className='SandwichMenu__menu-wrapper'>
          <div onClick={onClickSandwich} className='sandwich-menu__overlay'></div>
          <div className='SandwichMenu__menu-container sandwich-menu'>
            <div className='sandwich-menu__inner'>
              <div className='sandwich-menu__switcher'>
                <LangSwitcher />
              </div>
              <ul className='sandwich-menu__list'>{menuItems}</ul>
              <div className='sandwich-menu__contacts'>
                <Button clicked={() => props.openPopup('callback')} theme='gray'>
                  {lang.CallbackButtonTitle}
                </Button>
                <a className='sandwich-menu__contact' href='mailto:box@bestplace.ai'>
                  box@bestplace.ai
                </a>
                {/* <a
                  className='sandwich-menu__contact'
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.linkedin.com/company/14038853/'
                >
                  linkedin
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (name) => dispatch({ type: actionTypes.OPEN_MODAL, modal: name }),
  };
};

export default connect(null, mapDispatchToProps)(SandwichMenu);
