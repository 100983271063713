import React, { Component } from 'react';
import Button from '../../components/UI/Button/Button';
import Map from '../../components/UI/Map/Map';
import './AboutUs.scss';

import * as actionTypes from '../../store/actions/actionTypes';
import * as lang from './lang';
import { connect } from 'react-redux';

class AboutUs extends Component {
  state = {
    pointers: [
      {
        coordinates: [-75.547489, 39.746098],
        message: lang.AboutMarkWilmington,
      },
      {
        coordinates: [-3.8443441, 40.4380986],
        message: lang.AboutMarkSpain,
      },
    ],
  };
  render() {
    return (
      <div className='AboutUs'>
        <div className='about-first'>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-5'>
                <h1 className='caption caption_1'>{lang.AboutUsHeader}</h1>
              </div>
              <div className='col-12 col-lg-5 offset-lg-1'>
                <p className='paragraph'>{lang.AboutUsDescription}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-10 col-lg-3 offset-lg-6'>
                <Button clicked={() => this.props.openPopup('callback')}>{lang.AboutContactButtonTitle}</Button>
              </div>
            </div>
          </div>
        </div>
        <Map pointers={this.state.pointers} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (name) => dispatch({ type: actionTypes.OPEN_MODAL, modal: name }),
  };
};

export default connect(null, mapDispatchToProps)(AboutUs);
