export const CPGShopperPatternsHeader = window.gettext('CPGShopperPatternsHeader');
export const CPGShopperPatternsDescription = window.gettext('CPGShopperPatternsDescription');
export const CallbackButtonTitle = window.gettext('CallbackButtonTitle');
export const CPGShopperPatternsSalesLift = window.gettext('CPGShopperPatternsSalesLift');
export const CPGShopperPatternsROI = window.gettext('CPGShopperPatternsROI');
export const CpgMapFirstHeader = window.gettext('CpgMapFirstHeader');
export const CpgMapFirstDescription = window.gettext('CpgMapFirstDescription');
export const CpgMapSecondHeader = window.gettext('CpgMapSecondHeader');
export const CpgShopsHeader = window.gettext('CpgShopsHeader');
export const CpgShopsDescription = window.gettext('CpgShopsDescription');
export const CPGPointBeach = window.gettext('CPGPointBeach');
export const CPGPointFootballStadium = window.gettext('CPGPointFootballStadium');
export const CPGPointMetro = window.gettext('CPGPointMetro');
export const CPGPointBusStop = window.gettext('CPGPointBusStop');
export const CPGPointVillage = window.gettext('CPGPointVillage');
export const CPGPointMall = window.gettext('CPGPointMall');
export const CPGPointSuperMarket = window.gettext('CPGPointSuperMarket');
export const CPGPointPark = window.gettext('CPGPointPark');
export const CPGPointGym = window.gettext('CPGPointGym');
export const CPGPointBusinessOffice = window.gettext('CPGPointBusinessOffice');
export const CPGPointsHeader = window.gettext('CPGPointsHeader');
export const CPGPointsDescription = window.gettext('CPGPointsDescription');
export const CPGProfitableLocationsParty = window.gettext('CPGProfitableLocationsParty');
export const CPGProfitableLocationsSport = window.gettext('CPGProfitableLocationsSport');
export const CPGProfitableLocationsWork = window.gettext('CPGProfitableLocationsWork');
export const CPGProfitableLocationsPicnic = window.gettext('CPGProfitableLocationsPicnic');
export const CPGProfitableLocationsShop = window.gettext('CPGProfitableLocationsShop');
export const CPGProfitableLocationsHeader = window.gettext('CPGProfitableLocationsHeader');
export const CPGProfitableLocationsDescription = window.gettext('CPGProfitableLocationsDescription');
