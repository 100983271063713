import * as actionTypes from '../actions/actionTypes';

const initialState = {
  callback: false,
  thankYou: false,
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        [action.modal]: true,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        [action.modal]: false,
      };
    default:
      return state;
  }
};

export default modals;
