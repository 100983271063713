import React from 'react';
import NewsList from '../../containers/NewsList/NewsList';
import './News.scss';

import * as lang from './lang';

const News = () => {
  return (
    <div className='News'>
      <div className='News-header'>
        <div className='content'>
          <div className='row'>
            <div className='col-12 col-lg-4 offset-lg-2'>
              <h1 className='caption caption_1'>{lang.BlogListHeader}</h1>
            </div>
            <div className='col-12 col-lg-5'>
              <p className='News-header__description'>{lang.BlogListDescription}</p>
            </div>
          </div>
        </div>
      </div>
      <NewsList />
    </div>
  );
};

export default News;
