import { Children, cloneElement } from 'react';

const HexContainer = (props) => {
  const getRandomDelay = () => {
    return (Math.random() * (props.maxDelay - props.minDelay) + props.minDelay).toFixed(3);
  };

  return Children.toArray(props.children).map((child, index) =>
    cloneElement(child, { key: index, style: { transitionDelay: getRandomDelay() + 's', opacity: 1 } })
  );
};

export default HexContainer;
