import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as langGetters from '../../../Pages/Cases/lang';

import './CaseItem.scss';

const CaseItem = ({ match, logo, companyName, description, slug }) => {
  const lang = match.params.lang;
  return (
    <div className='col-12 col-lg-6'>
      <Link to={'/' + lang + '/cases/' + slug}>
        <div className='CaseItem'>
          <div className='CaseItem__logo'>
            <img src={logo} alt={companyName} />
          </div>
          <h3 className='CaseItem__name'>{companyName}</h3>
          <p className='CaseItem__description'>{description}</p>
          <div className='CaseItem__link'>
            {langGetters.CasesListDetailButton}{' '}
            <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M0.999972 0.999972L5 5L0.999972 9.00003' stroke='#017BE5' strokeWidth='2' />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  );
};

CaseItem.propTypes = {
  logo: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default withRouter(CaseItem);
