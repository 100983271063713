import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import parse from 'html-react-parser';

import * as lang from './lang';

import { TweenMax } from 'gsap';
import twoFingerMapboxPan from './twoFingerMapboxPan';

import './Map.scss';

mapboxgl.accessToken = 'pk.eyJ1IjoicGVzdGhlZG9nIiwiYSI6ImNrbWYzaGJmMTMxZWIyb253dmJhbnRrZmsifQ.6FxjLF34jdEogddPE29Xsw';

function isMacintosh() {
  return navigator.platform.indexOf('Mac') > -1;
}

const Map = (props) => {
  const container = useRef();
  const [options] = useState({
    style: 'mapbox://styles/mapbox/light-v10',
    center: [40, 50],
    zoom: 1.3,
    controls: true,
  });

  const parent = useRef();
  const map = useRef();
  const ctrl = useRef(false);
  const showOverlay = useRef(false);
  const inMap = useRef(false);
  const overlayRef = useRef();
  const hideTimer = () => {
    return setTimeout(() => {
      TweenMax.to(overlayRef.current, 0.3, { autoAlpha: 0 });
      timer.current = false;
    }, 1000);
  };
  const timer = useRef();

  useEffect(() => {
    let zoom = options.zoom;
    let center = options.center;

    if (window.innerWidth < 769) {
      zoom = 1.3;
      center = [-20, 50];
    }
    map.current = new mapboxgl.Map({
      container: container.current,
      style: options.style,
      dragPan: true,
      center: center,
      zoom: zoom,
      showZoom: true,
    });

    twoFingerMapboxPan(map.current);

    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();

    props.pointers.forEach((pointer) => {
      const el = document.createElement('div');
      el.classList.add('map-marker');
      el.style.width = '59px';
      el.style.height = '59px';

      if (window.innerWidth < 992) {
        el.style.width = '39px';
        el.style.height = '39px';
      }
      el.innerHTML =
        "<div class='map-marker__text'>" +
        pointer.message +
        "</div> <div class='map-marker__polygon'> <svg width='37' height='30' viewBox='0 0 37 30' fill='none' xmlns='http://www.w3.org/2000/svg' > <path d='M18.5003 29.9993L0.967494 0.832596L36.033 0.832599L18.5003 29.9993Z' fill='#017BE5' /> </svg> </div>";

      new mapboxgl.Marker(el).setLngLat(pointer.coordinates).addTo(map.current);
    });
  }, [options, props.pointers]);

  const scrollHandler = useCallback((e) => {
    if (ctrl.current) {
      showOverlay.current = false;

      if (!map.current.scrollZoom._enabled) map.current.scrollZoom.enable();
    } else {
      if (map.current.scrollZoom._enabled) map.current.scrollZoom.disable();

      if (inMap.current) {
        showOverlay.current = true;
      } else {
        showOverlay.current = false;
      }
    }
    if (showOverlay.current) {
      TweenMax.to(overlayRef.current, 0.3, { autoAlpha: 1 });
      if (timer.current || !timer.current) {
        clearTimeout(timer.current);
        timer.current = hideTimer();
      }
    } else {
      TweenMax.to(overlayRef.current, 0.3, { autoAlpha: 0 });
    }
  }, []);

  const handleCtrl = (e) => {
    ctrl.current = e.metaKey || e.ctrlKey;
    if (ctrl.current) {
      showOverlay.current = false;
      TweenMax.to(overlayRef.current, 0.3, { autoAlpha: 0 });
    }
  };

  const handleMouseEnter = (e) => {
    inMap.current = e.type === 'mouseenter';
  };

  useEffect(() => {
    const mapContainer = parent.current;

    window.addEventListener('wheel', scrollHandler, false);
    document.addEventListener('keydown', handleCtrl);
    mapContainer.addEventListener('mouseenter', handleMouseEnter);
    mapContainer.addEventListener('mouseleave', handleMouseEnter);
    document.addEventListener('keyup', handleCtrl);

    return () => {
      window.removeEventListener('wheel', scrollHandler, false);
      document.removeEventListener('keydown', handleCtrl);
      document.removeEventListener('keyup', handleCtrl);
      mapContainer.removeEventListener('mouseenter', handleMouseEnter);
      mapContainer.removeEventListener('mouseleave', handleMouseEnter);
    };
  }, [scrollHandler]);

  const isMac = isMacintosh();
  const key = isMac ? '&#8984;' : 'Ctrl';

  const overlay = (
    <div className='Map__overlay' ref={overlayRef} style={{ visibility: 'hidden' }}>
      To zoom this map {parse(key)} + scroll
    </div>
  );
  return (
    <Fragment>
      <link href='https://api.tiles.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css' rel='stylesheet' />
      <div className='Map' ref={parent}>
        <div className='Map__inner' ref={container}></div>

        <div className='map-contacts'>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-4 offset-lg-6'>
                <div className='map-contacts__inner'>
                  <div className='map-contacts__caption'>{lang.MapBoxHeader}</div>
                  <a className='map-contacts__email' href='mailto:box@bestplace.ai'>
                    box@bestplace.ai
                  </a>
                  <div className='map-contacts__item'>
                    <div className='map-contacts__name'>
                      <b>{lang.SpainContactName}</b>
                    </div>
                    <a className='map-contacts__phone' href='tel:+34963943497'>
                      +34 (963)943 497
                    </a>
                  </div>
                  <div className='map-contacts__item'>
                    <div className='map-contacts__name'>
                      <b>{lang.USContactName}</b>
                    </div>
                    <a className='map-contacts__phone' href='tel:+12563611521'>
                      +1 (256) 361 1521
                    </a>
                  </div>
                  {/* <div className='map-contacts__item'>
                    <div className='map-contacts__name'>
                      <b>{window.gettext('RotterdamCityName')}</b> - {window.gettext('RotterdamCityArea')}
                    </div>
                    <a className='map-contacts__phone' href='tel:+48889799971'>
                      +48 889 799 971
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {overlay}
      </div>
    </Fragment>
  );
};

Map.propTypes = {
  pointers: PropTypes.array.isRequired,
};

export default Map;
