import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.scss';

const Input = (props) => {
  const [focused, setFocused] = useState(false);
  const [stored, setStored] = useState(false);
  const classNames = [classes.Input];

  if (props.error) classNames.push(classes.Input_error);
  if (focused) classNames.push(classes.Input_focused);
  if (stored) classNames.push(classes.Input_stored);

  const changeHandler = (e) => {
    props.changed(e.target.value);
    const length = e.target.value.length > 0;
    setStored(length);
  };

  return (
    <label className={classNames.join(' ')}>
      <div className={classes.Input__label}>{props.label}</div>
      <input
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={changeHandler}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </label>
  );
};

Input.defaultProps = {
  type: 'text',
  error: false,
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  changed: PropTypes.func.isRequired,
  validation: PropTypes.func,
  error: PropTypes.bool,
};

export default Input;
