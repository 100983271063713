import './assets/scss/main.scss';
import Header from './components/Header/Header';
import { Component, createRef, useEffect } from 'react';
import Pages from './Pages/Pages';
import Footer from './components/Footer/Footer';
import { Switch, Route, withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import Modal from './components/UI/Modal/Modal';
import * as actionTypes from './store/actions/actionTypes';
import * as actions from './store/actions/index.js';

import * as langGetters from './forms/CallbackForm/lang';

import { connect } from 'react-redux';
import CallbackForm from './forms/CallbackForm/CallbackForm';

class App extends Component {
  state = {
    mainMenu: [
      // {
      //   name: window.gettext('MenuRetail'),
      //   link: '/' + this.props.lang.value + '/',
      // },
      // {
      //   name: window.gettext('MenuCPG'),
      //   link: '/' + this.props.lang.value + '/cpg',
      // },
    ],
    burgerMenu: [
      {
        name: window.gettext('MenuCPG'),
        link: '/' + this.props.lang.value + '/',
      },
      {
        name: window.gettext('MenuRetail'),
        link: '/' + this.props.lang.value + '/retail',
      },
      {
        name: window.gettext('MenuAbout'),
        link: '/' + this.props.lang.value + '/about-us',
      },
      // {
      //   name: window.gettext('MenuCases'),
      //   link: '/' + this.props.lang.value + '/cases',
      // },
      ...(this.props.lang === 'ru'
        ? [
            {
              name: window.gettext('MenuBlog'),
              link: '/' + this.props.lang.value + '/blog',
            },
          ]
        : []),
    ],
    footerMenu: [
      {
        name: window.gettext('MenuCPG'),
        link: '/' + this.props.lang.value + '/',
      },
      {
        name: window.gettext('MenuRetail'),
        link: '/' + this.props.lang.value + '/retail',
      },
      {
        name: window.gettext('MenuAbout'),
        link: '/' + this.props.lang.value + '/about-us',
      },
      // {
      //   name: window.gettext('MenuCases'),
      //   link: '/' + this.props.lang.value + '/cases',
      // },
      ...(this.props.lang === 'ru'
        ? [
            {
              name: window.gettext('MenuBlog'),
              link: '/' + this.props.lang.value + '/blog',
            },
          ]
        : []),
    ],
    layoutHeight: 0,
    footerHeight: 0,
  };

  layout = createRef(null);
  footer = createRef(null);
  footerInner = createRef();
  footerBottom = createRef();

  calculateLayoutMargin = () => {
    if (window.innerWidth < 992) {
      return false;
    }
    const footerHeight =
      this.footer.current.getBoundingClientRect().height + this.footerInner.current.getBoundingClientRect().height;
    this.layout.current.style.marginBottom = footerHeight + 'px';
    this.setState({
      layoutHeight: this.layout.current.getBoundingClientRect().height - window.innerHeight,
      footerHeight: footerHeight,
    });
  };

  footerParallax = (e) => {
    if (window.innerWidth < 992) return false;
    const scrollPosition = window.scrollY;
    if (scrollPosition >= this.state.layoutHeight) {
      //if (percents > 0) percents = 0;
      let percents =
        (this.state.layoutHeight + this.footerInner.current.getBoundingClientRect().height - scrollPosition) * 0.3;
      if (percents < -this.footerBottom.current.getBoundingClientRect().height)
        percents = -this.footerBottom.current.getBoundingClientRect().height;

      TweenMax.set(this.footerInner.current, { y: percents });
    }

    requestAnimationFrame(this.footerParallax);
  };

  componentDidMount() {
    this.calculateLayoutMargin();
    window.addEventListener('resize', this.calculateLayoutMargin);
    requestAnimationFrame(this.footerParallax);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.newsLoading !== prevProps.newsLoading ||
      this.props.casesLoading !== prevProps.casesLoading
    ) {
      cancelAnimationFrame(this.footerParallax);

      setTimeout(() => {
        this.calculateLayoutMargin();
        requestAnimationFrame(this.footerParallax);
      }, 100);
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.modals !== nextProps.modals ||
      this.props.newsLoading !== nextProps.newsLoading ||
      this.props.casesLoading !== nextProps.casesLoading
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateLayoutMargin);
    cancelAnimationFrame(this.footerParallax);
  }

  langRedirect = (props) => {
    const Comp = () => {
      useEffect(() => {
        const waiting = async () => {
          await this.props.getLang();
          const defaultLang = this.props.lang.value;
          const redirectPath = `/${defaultLang}${props.history.location.pathname}`;
          window.location.replace(redirectPath);
        };
        waiting();
      }, []);
      return null;
    };
    return <Comp />;
  };

  render() {
    return (
      <div className='App'>
        <Header menu={this.state.mainMenu} burgerMenu={this.state.burgerMenu} />
        <div className='Layout' ref={this.layout}>
          <Switch>
            <Route exact path='/:lang/*' component={Pages} />
            <Route path='*' render={this.langRedirect} />
          </Switch>
        </div>
        <Footer
          menu={this.state.footerMenu}
          propRef={this.footer}
          inner={this.footerInner}
          bottom={this.footerBottom}
          loaded={this.calculateLayoutMargin}
        />
        <Modal show={this.props.modals.callback} closed={() => this.props.closePopup('callback')}>
          <CallbackForm />
        </Modal>
        <Modal show={this.props.modals.thankYou} closed={() => this.props.closePopup('callback')}>
          <div className='thank-you'>
            <div className='caption caption_1'>{langGetters.ThankYouHeader}</div>
            <p className='paragraph'>{langGetters.ThankYouDescription}</p>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modals: state.modals,
    lang: state.language.active,
    newsLoading: state.news.loading,
    casesLoading: state.cases.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closePopup: (name) => dispatch({ type: actionTypes.CLOSE_MODAL, modal: name }),
    getLang: () => dispatch(actions.getLanguage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
