import React, { Component, Fragment } from 'react';
import FullpageWrapper from '../../containers/FullpageWrapper/FullpageWrapper';
import Fullpage from '../../components/Fullpage/Fullpage';
import Button from '../../components/UI/Button/Button';
import MapPoint from '../../components/UI/MapPoint/MapPoint';
import * as actionTypes from '../../store/actions/actionTypes';

import SimpleParallax from 'simple-parallax-js';
import { TweenMax } from 'gsap';
import { connect } from 'react-redux';
import * as lang from './lang';
import parse from 'html-react-parser';

import map from '../../assets/images/common/main_map.svg';
import cpgFirst from '../../assets/images/cpg/cpg-first.png';
import mapYellow from '../../assets/images/cpg/map-yellow.svg';
import mapGreen from '../../assets/images/cpg/map-green.svg';

import shops from '../../assets/images/cpg/shops.svg';
import shopsMobile from '../../assets/images/cpg/shops-mobile.svg';

import shopOneStateOne from '../../assets/images/cpg/shop-one-state-1.svg';
import shopOneStateOneMobile from '../../assets/images/cpg/shop-one-state-1-mobile.svg';

import shopOneStateTwo from '../../assets/images/cpg/shop-one-state-2.svg';
import shopOneStateTwoMobile from '../../assets/images/cpg/shop-one-state-2-mobile.svg';

import shopOneActiveOne from '../../assets/images/cpg/shop-one-active-1.svg';
import shopOneActiveOneMobile from '../../assets/images/cpg/shop-one-active-1-mobile.svg';

import shopTwoStateOne from '../../assets/images/cpg/shop-two-state-1.svg';
import shopTwoStateOneMobile from '../../assets/images/cpg/shop-two-state-1-mobile.svg';

import shopTwoActiveOne from '../../assets/images/cpg/shop-two-active-1.svg';
import shopTwoActiveOneMobile from '../../assets/images/cpg/shop-two-active-1-mobile.svg';

import shopTwoActiveTwo from '../../assets/images/cpg/shop-two-active-2.svg';
import shopTwoActiveTwoMobile from '../../assets/images/cpg/shop-two-active-2-mobile.svg';

import shopRoofs from '../../assets/images/cpg/shop-roofs.svg';
import shopRoofsMobile from '../../assets/images/cpg/shop-roofs-mobile.svg';

import mapPoint1 from '../../assets/images/cpg/points/point1.svg';
import mapPoint2 from '../../assets/images/cpg/points/point2.svg';
import mapPoint3 from '../../assets/images/cpg/points/point3.svg';
import mapPoint4 from '../../assets/images/cpg/points/point4.svg';
import mapPoint5 from '../../assets/images/cpg/points/point5.svg';
import mapPoint6 from '../../assets/images/cpg/points/point6.svg';
import mapPoint7 from '../../assets/images/cpg/points/point7.svg';
import mapPoint8 from '../../assets/images/cpg/points/point8.svg';
import mapPoint9 from '../../assets/images/cpg/points/point9.svg';
import mapPoint10 from '../../assets/images/cpg/points/point10.svg';

import party from '../../assets/images/cpg/party.jpg';
import sport from '../../assets/images/cpg/sport.jpg';
import work from '../../assets/images/cpg/work.jpg';
import picnic from '../../assets/images/cpg/picnic.jpg';
import shop from '../../assets/images/cpg/shop.jpg';

import './CpgPage.scss';

class CpgPage extends Component {
  componentDidMount() {
    if (window.innerWidth > 768) {
      document.querySelectorAll('.cpg-seventh__image img').forEach((image) => {
        new SimpleParallax(image, {
          scale: 1.2,
          orientation: 'down',
          delay: 0.5,
        });
      });

      requestAnimationFrame(this.parallaxText);
    }
  }

  parallaxText = () => {
    const scrollY = window.scrollY;
    document.querySelectorAll('.cpg-seventh__text').forEach((text) => {
      const top = text.getBoundingClientRect().top + window.pageYOffset - document.body.clientTop;
      // console.log(top);
      const tiltY = (scrollY - top) * 0.05;

      TweenMax.to(text, { y: -tiltY });
    });

    requestAnimationFrame(this.parallaxText);
  };

  render() {
    const childrens = (
      <Fragment>
        <Fullpage className='cpg-shopper-patterns has-circle'>
          <div className='Fullpage__overlay'></div>
          <img className='cpg-shopper-patterns__image' src={cpgFirst} alt='' />
          <div className='Fullpage__mobile-circle'></div>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-5 offset-lg-6'>
                <h1 className='caption caption_1'>{lang.CPGShopperPatternsHeader}</h1>
                <p className='paragraph'>
                  {/* Find stores with the highest sales potential and optimize product mix based on local consumers behaviour */}
                  {lang.CPGShopperPatternsDescription}
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-10 col-lg-2 offset-lg-6'>
                <Button clicked={() => this.props.openPopup('callback')} className='retail-first__button'>
                  {lang.CallbackButtonTitle}
                </Button>
              </div>
            </div>
            <div className='row cpg-shopper-patterns__gradient'>
              <div className='d-lg-block col-12 col-lg-3 offset-lg-6'>
                <div className='gradient-text'>
                  <strong>10%</strong>
                </div>
                <p className='paragraph paragraph_dark'>{lang.CPGShopperPatternsSalesLift}</p>
              </div>
              <div className='d-lg-block col-12 col-lg-3'>
                <div className='gradient-text'>
                  <strong>15%</strong>
                </div>
                <p className='paragraph paragraph_dark'>{lang.CPGShopperPatternsROI}</p>
              </div>
            </div>
          </div>
        </Fullpage>
        <Fullpage className='cpg-second cpg-map has-circle'>
          <img className='cpg-map__item cpg-second__image' src={mapYellow} alt='' />
          <div className='Fullpage__mobile-circle'></div>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-5 offset-lg-6'>
                <div className='caption caption_1 caption-one'>
                  {/* <b>Convert 27%</b> of the most valuable DMAs <b>from №2</b> market share */}
                  {parse(lang.CpgMapFirstHeader)}
                </div>
              </div>
            </div>
          </div>
        </Fullpage>
        <Fullpage className='cpg-third cpg-map has-circle'>
          <img className='cpg-map__item cpg-third__image' src={mapYellow} alt='' />

          <img className='cpg-map__item cpg-third__green' src={mapGreen} alt='' />

          <div className='Fullpage__mobile-circle'></div>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-5 offset-lg-6'>
                <div className='caption caption_1 caption-one'>
                  {/* <b>Convert 27%</b> of the most valuable DMAs <b>from №2</b> market share */}
                  {parse(lang.CpgMapFirstHeader)}
                </div>
                <p className='paragraph caption-two'>{lang.CpgMapFirstDescription}</p>
              </div>
            </div>
          </div>
        </Fullpage>
        <Fullpage className='cpg-fourth cpg-shops has-circle'>
          <div className='Fullpage__overlay'></div>
          <div className='Fullpage__mobile-circle'></div>
          <div className='cpg-shops__container'>
            <picture>
              <source media='(max-width: 768px)' srcSet={shopsMobile} />
              <img className='cpg-shops__image' src={shops} alt='' />
            </picture>
            <picture>
              <source media='(max-width: 768px)' srcSet={shopOneStateOneMobile} />
              <img className='shop-one-state-one' src={shopOneStateOne} alt='' />
            </picture>
            <picture>
              <source media='(max-width: 768px)' srcSet={shopOneStateTwoMobile} />
              <img className='shop-one-state-two' src={shopOneStateTwo} alt='' />
            </picture>
            <picture>
              <source media='(max-width: 768px)' srcSet={shopTwoStateOneMobile} />
              <img className='shop-two-state-one' src={shopTwoStateOne} alt='' />
            </picture>
          </div>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-5 offset-lg-6'>
                <h1 className='caption caption_1 caption-one'>
                  {/* What if your sales team was twice as efficient */}
                  {lang.CpgShopsHeader}
                </h1>
              </div>
            </div>
          </div>
        </Fullpage>
        <Fullpage className='cpg-fifth cpg-shops has-circle'>
          <div className='Fullpage__overlay'></div>
          <div className='Fullpage__mobile-circle'></div>
          <picture>
            <source media='(max-width: 768px)' srcSet={shopsMobile} />
            <img className='cpg-shops__image' src={shops} alt='' />
          </picture>
          <picture>
            <source media='(max-width: 768px)' srcSet={shopRoofsMobile} />
            <img className='cpg-shops__roofs d-none d-lg-block' src={shopRoofs} alt='' />
          </picture>
          <div className='cpg-shops__container'>
            <picture>
              <source media='(max-width: 768px)' srcSet={shopOneActiveOneMobile} />
              <img className='shop-one-active-one d-none d-lg-block' src={shopOneActiveOne} alt='' />
            </picture>
            <picture>
              <source media='(max-width: 768px)' srcSet={shopTwoActiveOneMobile} />
              <img className='shop-two-active-one d-none d-lg-block' src={shopTwoActiveOne} alt='' />
            </picture>
            <picture>
              <source media='(max-width: 768px)' srcSet={shopTwoActiveTwoMobile} />
              <img className='shop-two-active-two d-none d-lg-block' src={shopTwoActiveTwo} alt='' />
            </picture>
          </div>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-5 offset-lg-6'>
                <h1 className='caption caption_1 caption-one'>
                  {/* What if your sales team was twice as efficient */}
                  {lang.CpgShopsHeader}
                </h1>
                <p className='paragraph caption-two'>
                  {/* In finding the best retail stores and the right assortment for those stores */}
                  {lang.CpgShopsDescription}
                </p>
              </div>
            </div>
          </div>
        </Fullpage>
        <Fullpage className='cpg-sixth has-circle'>
          <div className='cpg-sixth__points'>
            <MapPoint
              width='9.65%'
              left='0'
              top='66.17%'
              icon={mapPoint1}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '0ms', animationDelay: '3000ms' }}
            >
              {lang.CPGPointBeach}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='47.91%'
              top='49.47%'
              icon={mapPoint2}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '50ms', animationDelay: '800ms' }}
            >
              {lang.CPGPointFootballStadium}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='24.62%'
              top='62.79%'
              icon={mapPoint3}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '100ms', animationDelay: '1400ms' }}
            >
              {lang.CPGPointMetro}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='49.81%'
              top='84.77%'
              icon={mapPoint4}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '150ms', animationDelay: '1000ms' }}
            >
              {lang.CPGPointBusStop}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='31.62%'
              top='34.67%'
              icon={mapPoint5}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '200ms', animationDelay: '1500ms' }}
            >
              {lang.CPGPointVillage}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='60.98%'
              top='0'
              icon={mapPoint6}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '250ms', animationDelay: '4000ms' }}
            >
              {lang.CPGPointMall}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='72.91%'
              top='67.44%'
              icon={mapPoint7}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '300ms', animationDelay: '4500ms' }}
            >
              {lang.CPGPointSuperMarket}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='5.49%'
              top='32.76%'
              icon={mapPoint8}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '300ms', animationDelay: '2000ms' }}
            >
              {lang.CPGPointPark}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='64.58%'
              top='27.48%'
              icon={mapPoint9}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '220ms', animationDelay: '1000ms' }}
            >
              {lang.CPGPointGym}
            </MapPoint>
            <MapPoint
              width='9.65%'
              left='74.05%'
              top='42.70%'
              icon={mapPoint10}
              absolute
              yellow
              pulse
              style={{ transitionDelay: '220ms', animationDelay: '3500ms' }}
            >
              {lang.CPGPointBusinessOffice}
            </MapPoint>
          </div>
          <div className='Fullpage__mobile-circle'></div>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-5 offset-lg-6'>
                <h1 className='caption caption_1'>{lang.CPGPointsHeader}</h1>
                <p className='paragraph'>{lang.CPGPointsDescription}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-2 offset-lg-6'>
                <Button clicked={() => this.props.openPopup('callback')} className='retail-first__button'>
                  {lang.CallbackButtonTitle}
                </Button>
              </div>
            </div>
          </div>
        </Fullpage>
      </Fragment>
    );

    let firstScreen = childrens;
    if (window.innerWidth >= 992) {
      firstScreen = (
        <FullpageWrapper>
          <div className='FullpageWrapper__image'>
            <img src={map} alt='' />
          </div>
          {childrens}
        </FullpageWrapper>
      );
    }
    return (
      <div className='CpgPage'>
        {firstScreen}
        <div className='cpg-seventh'>
          <div className='cpg-seventh__background'>
            <img src={map} alt='' />
          </div>
          <div className='Fullpage__overlay'></div>
          <div className='content'>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row cpg-seventh__row_party'>
                  <div className='col-12 col-lg-8'>
                    <div className='cpg-seventh__item cpg-seventh__item_party cpg-seventh__item_left'>
                      <div className='cpg-seventh__text'>{lang.CPGProfitableLocationsParty}</div>
                      <div className='cpg-seventh__image'>
                        <img src={party} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row cpg-seventh__row_sport'>
                  <div className='col-8 offset-4 col-lg-6 offset-lg-6'>
                    <div className='cpg-seventh__item cpg-seventh__item_sport cpg-seventh__item_right'>
                      <div className='cpg-seventh__text'>{lang.CPGProfitableLocationsSport}</div>
                      <div className='cpg-seventh__image'>
                        <img src={sport} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row cpg-seventh__row_work'>
                  <div className='col-lg-6 col-8'>
                    <div className='cpg-seventh__item cpg-seventh__item_work cpg-seventh__item_left'>
                      <div className='cpg-seventh__text'>{lang.CPGProfitableLocationsWork}</div>
                      <div className='cpg-seventh__image'>
                        <img src={work} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row cpg-seventh__row_picnic'>
                  <div className='col-8 offset-4'>
                    <div className='cpg-seventh__item cpg-seventh__item_picnic cpg-seventh__item_right'>
                      <div className='cpg-seventh__text'>{lang.CPGProfitableLocationsPicnic}</div>
                      <div className='cpg-seventh__image'>
                        <img src={picnic} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row cpg-seventh__row_shop'>
                  <div className='col-8 col-lg-6 offset-lg-1'>
                    <div className='cpg-seventh__item cpg-seventh__item_shop cpg-seventh__item_left'>
                      <div className='cpg-seventh__text'>{lang.CPGProfitableLocationsShop}</div>
                      <div className='cpg-seventh__image'>
                        <img src={shop} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-5 cpg-seventh__text-column'>
                <div className='cpg-seventh__text-container'>
                  <h1 className='caption caption_1'>{lang.CPGProfitableLocationsHeader}</h1>
                  <p className='paragraph'>{lang.CPGProfitableLocationsDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (name) => dispatch({ type: actionTypes.OPEN_MODAL, modal: name }),
  };
};

export default connect(null, mapDispatchToProps)(CpgPage);
