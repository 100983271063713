import * as actionTypes from '../actions/actionTypes';

const initialState = {
  previousPage: null,
  nextPage: null,
  currentPage: null,
  pagesCount: null,
  news: [],
  loading: false,
  detail: null,
  notFound: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NEWS:
      return {
        ...state,
        previousPage: action.previousPage,
        nextPage: action.nextPage,
        currentPage: action.currentPage,
        pagesCount: action.pagesCount,
        news: state.news.concat(action.news),
        loading: false,
      };

    case actionTypes.SET_NEWS:
      return {
        ...state,
        previousPage: action.previousPage,
        nextPage: action.nextPage,
        currentPage: action.currentPage,
        pagesCount: action.pagesCount,
        news: action.news,
        loading: false,
      };

    case actionTypes.FETCH_NEWS_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.FETCH_NEWS_DETAIL_START:
      return {
        ...state,
        detail: null,
        loading: true,
        notFound: false,
      };

    case actionTypes.FETCH_NEWS_DETAIL_NOT_FOUND:
      return {
        ...state,
        detail: null,
        loading: false,
        notFound: true,
      };

    case actionTypes.SET_NEWS_DETAIL:
      return {
        ...state,
        detail: action.detail,
        loading: false,
        notFound: false,
      };

    default:
      return state;
  }
};

export default reducer;
