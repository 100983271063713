import * as actionTypes from './actionTypes';
import axios from 'axios';

export const setLanguage = (lang) => {
  return {
    type: actionTypes.SET_LANGUAGE,
    activeLanguage: lang,
  };
};

export const getLanguage = () => {
  return (dispatch) => {
    axios
      .get('/get-language/', {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        dispatch(setLanguage(response.data));
      });
  };
};
