import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { SET_LANGUAGE } from '../../../store/actions/actionTypes';
import { withRouter } from 'react-router-dom';

import useOutsideHandler from '../../../hooks/useOutsideHandler';

import './LangSwitcher.scss';

const LangSwitcher = (props) => {
  const [showItems, setShowItems] = useState(false);
  const compRef = useRef(null);
  const nodeRef = useRef(null);
  const listRef = useRef(null);
  const duration = 200;

  const items = props.items.map((item) => {
    const classes = ['LangSwitcher__item'];
    let clickHandler = (value) => {
      //props.switchLanguage(value);
      toggleHandler();
      const expire = new Date(new Date().setFullYear(new Date().getFullYear() + 5));
      const pathName = props.location.pathname;
      const pathArray = pathName.split('/');
      pathArray[1] = value.value;
      document.cookie = `SITE_LANG=${value.value}; path=/; expires=${expire.toUTCString()}`;
      window.location.replace(pathArray.join('/'));
    };
    if (item.value === props.activeLanguage.value) {
      clickHandler = () => false;
      classes.push('LangSwitcher__item_active');
    }
    return (
      <li onClick={() => clickHandler(item)} key={item.value} className={classes.join(' ')}>
        {item.text}
      </li>
    );
  });

  const itemsContainer = showItems ? (
    <ul className='LangSwitcher__items' ref={listRef}>
      {items}
    </ul>
  ) : null;

  const classes = ['LangSwitcher'];
  const toggleHandler = () => {
    const type = !showItems ? 'opening' : 'closing';
    if (type === 'opening') {
      setShowItems(true);
      // const height = listRef.current.getBoundingClientRect.offsetHeight();
      setTimeout(() => {
        const height = listRef.current.getBoundingClientRect().height;
        nodeRef.current.style.height = height + 'px';
        // console.log(height);
      }, 0);
    } else {
      setTimeout(() => {
        nodeRef.current.style.height = 0 + 'px';
        setTimeout(() => setShowItems(false), duration);
      }, 0);
    }
  };

  const handleClickOutside = () => {
    if (showItems) toggleHandler();
  };

  useOutsideHandler(compRef, handleClickOutside);

  if (showItems) classes.push('LangSwitcher_opened');
  return (
    <div className={classes.join(' ')} ref={compRef}>
      <div onClick={toggleHandler} className='LangSwitcher__toggle'>
        {props.activeLanguage.text}
        <svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M0.999972 0.999972L5 5L9.00003 0.999972' strokeWidth='2' />
        </svg>
      </div>

      <div
        className='LangSwitcher__container'
        style={{ overflow: 'hidden', transition: `height ${duration / 1000}s` }}
        ref={nodeRef}
      >
        {itemsContainer}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.language.items,
    activeLanguage: state.language.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchLanguage: (lang) => dispatch({ type: SET_LANGUAGE, activeLanguage: lang }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LangSwitcher));
