import * as actionTypes from '../actions/actionTypes';

const initialState = {
  previousPage: null,
  nextPage: null,
  currentPage: null,
  pagesCount: null,
  cases: [],
  loading: false,
  detail: null,
  lastCase: null,
  notFound: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CASES_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.SET_CASES:
      return {
        ...state,
        previousPage: action.previousPage,
        nextPage: action.nextPage,
        currentPage: action.currentPage,
        pagesCount: action.pagesCount,
        cases: action.cases,
        loading: false,
      };

    case actionTypes.ADD_CASES:
      return {
        ...state,
        previousPage: action.previousPage,
        nextPage: action.nextPage,
        currentPage: action.currentPage,
        pagesCount: action.pagesCount,
        cases: state.cases.concat(action.cases),
        loading: false,
      };

    case actionTypes.FETCH_CASES_DETAIL_START:
      return {
        ...state,
        detail: null,
        loading: true,
        notFound: false,
      };

    case actionTypes.SET_CASES_DETAIL:
      return {
        ...state,
        loading: false,
        detail: action.detail,
        notFound: false,
      };

    case actionTypes.FETCH_CASES_DETAIL_NOT_FOUND:
      return {
        ...state,
        loading: false,
        detail: null,
        notFound: true,
      };

    case actionTypes.FETCH_LAST_CASE_START:
      return {
        ...state,
        loading: true,
        lastCase: null,
      };

    case actionTypes.FETCH_LAST_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        lastCase: action.lastCase,
      };

    default:
      return state;
  }
};

export default reducer;
