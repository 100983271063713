export const SET_LANGUAGE = 'SET_LANGUAGE';
export const GET_LANGUAGE = 'GET_LANGUAGE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'FETCH_NEWS_FAIL';
export const SET_NEWS = 'SET_NEWS';
export const ADD_NEWS = 'ADD_NEWS';
export const FETCH_NEWS_DETAIL_START = 'FETCH_NEWS_DETAIL_START';
export const FETCH_NEWS_DETAIL_NOT_FOUND = 'FETCH_NEWS_DETAIL_NOT_FOUND';
export const SET_NEWS_DETAIL = 'SET_NEWS_DETAIL';

export const FETCH_CASES_START = 'FETCH_CASES_START';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_FAIL = 'FETCH_CASES_FAIL';
export const SET_CASES = 'SET_CASES';
export const ADD_CASES = 'ADD_CASES';
export const FETCH_CASES_DETAIL_START = 'FETCH_CASES_DETAIL_START';
export const FETCH_CASES_DETAIL_NOT_FOUND = 'FETCH_CASES_DETAIL_NOT_FOUND';
export const SET_CASES_DETAIL = 'SET_CASES_DETAIL';

export const FETCH_LAST_CASE_START = 'FETCH_LAST_CASE_START';
export const FETCH_LAST_CASE_SUCCESS = 'FETCH_LAST_CASE_SUCCESS';
