import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { withRouter } from 'react-router-dom';

import NewsItem from './NewsItem/NewsItem';
import Pagination from '../../components/UI/Pagination/Pagination';

import './NewsList.scss';

const NewsList = (props) => {
  const fetchNews = props.fetchNews;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');

    const handlePopState = (e) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const page = urlParams.get('page');
      fetchNews(page);
    };

    window.addEventListener('popstate', handlePopState);
    fetchNews(page);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [fetchNews]);

  const newsItems = props.news.map((newsItem) => {
    return <NewsItem {...newsItem} key={newsItem.id} />;
  });

  const loadMore = (e) => {
    e.preventDefault();
    const nextPage = props.currentPage + 1;
    fetchNews(nextPage, 'add');
    props.history.push('?page=' + nextPage);
  };

  const loadMoreBtn = props.nextPage ? (
    <button onClick={loadMore} className='NewsList__loadmore'>
      Load more
    </button>
  ) : null;

  const setPage = (page) => {
    fetchNews(page);
    props.history.push('?page=' + page);
  };

  const pagination =
    props.pagesCount > 1 ? (
      <Pagination currentPage={props.currentPage} pagesCount={props.pagesCount} onSetPage={setPage} />
    ) : null;

  const navigation =
    pagination || loadMoreBtn ? (
      <div className='NewsList__navigation'>
        <div className='row'>
          <div className='col-2 offset-5'>{loadMoreBtn}</div>
          <div className='col-3 offset-2'>{pagination}</div>
        </div>
      </div>
    ) : null;

  return (
    <div className='NewsList'>
      <div className='content'>
        <div className='NewsList__container'>
          <div className='row NewsList__row'>{newsItems}</div>
        </div>
        {navigation}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.news,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNews: (page = 1, type = 'set') => dispatch(actions.fetchNews(type, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewsList));
