export const RetailProfitableLocationsDescription = window.gettext('RetailProfitableLocationsDescription');
export const RetailProfitableLocationsHeader = window.gettext('RetailProfitableLocationsHeader');
export const CallbackButtonTitle = window.gettext('CallbackButtonTitle');
export const RetailHeatMapHeader = window.gettext('RetailHeatMapHeader');
export const RetailHeatMapDescription = window.gettext('RetailHeatMapDescription');
export const RetailHeatMapBlueText = window.gettext('RetailHeatMapBlueText');
export const RetailPredictionModelHeader = window.gettext('RetailPredictionModelHeader');
export const RetailPredictionModelDescription = window.gettext('RetailPredictionModelDescription');
export const RetailPredictionModelBlueText = window.gettext('RetailPredictionModelBlueText');
export const RetailWorkflowHeader = window.gettext('RetailWorkflowHeader');
export const RetailWorkflowDescription = window.gettext('RetailWorkflowDescription');
export const RetailWorkflowBlueText = window.gettext('RetailWorkflowBlueText');
export const RetailCirclesYellow = window.gettext('RetailCirclesYellow');
export const RetailCirclesGreen = window.gettext('RetailCirclesGreen');
export const RetailCirclesBlue = window.gettext('RetailCirclesBlue');
export const RetailCirclesHeader = window.gettext('RetailCirclesHeader');
export const RetailCirclesDescription = window.gettext('RetailCirclesDescription');
export const RetailDataSourcesHeader = window.gettext('RetailDataSourcesHeader');
export const RetailDataSourcesMapsTransport = window.gettext('RetailDataSourcesMapsTransport');
export const RetailDataSourcesGPS = window.gettext('RetailDataSourcesGPS');
export const RetailDataSourcesMorphText = window.gettext('RetailDataSourcesMorphText');
export const RetailDataSourcesRealEstate = window.gettext('RetailDataSourcesRealEstate');
export const RetailDataSourcesGovernmentData = window.gettext('RetailDataSourcesGovernmentData');
export const RetailClientsHeader = window.gettext('RetailClientsHeader');
