import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Dotdotdot from 'react-dotdotdot';
import PropTypes from 'prop-types';
import moment from 'moment';

import './NewsItem.scss';

const NewsItem = ({ match, image, createdVisible, name, description, slug }) => {
  const lang = match.params.lang;
  const url = '/' + lang + '/blog/' + slug;
  return (
    <div className='col-12 col-lg-4 NewsItem-column'>
      <div className='NewsItem'>
        <Link to={url}>
          <div className='NewsItem__image' style={{ backgroundImage: `url(${image})` }}></div>
        </Link>
        <div className='NewsItem__date'>{moment(createdVisible).format('D MMMM YYYY')}</div>
        <Link to={url}>
          <h3 className='NewsItem__name'>{name}</h3>
        </Link>

        <Dotdotdot clamp={3}>
          <p className='NewsItem__description'>{description}</p>
        </Dotdotdot>
      </div>
    </div>
  );
};

NewsItem.propTypes = {
  image: PropTypes.string.isRequired,
  date: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
};

export default withRouter(NewsItem);
