export const CasesListHeader = window.gettext('CasesListHeader');
export const CasesListDescription = window.gettext('CasesListDescription');
export const CasesListDetailButton = window.gettext('CasesListDetailButton');
export const CaseDetailBackButton = window.gettext('CaseDetailBackButton');
export const CaseDetailCompanyLabel = window.gettext('CaseDetailCompanyLabel');
export const CaseDetailDateLabel = window.gettext('CaseDetailDateLabel');
export const CaseDetailOjbectiveLabel = window.gettext('CaseDetailOjbectiveLabel');
export const CaseDetailIdeaLabel = window.gettext('CaseDetailIdeaLabel');
export const CaseDetailOtherPublications = window.gettext('CaseDetailOtherPublications');
export const CaseDetailAllPublications = window.gettext('CaseDetailAllPublications');
export const CasesListLoadMoreButton = window.gettext('CasesListLoadMoreButton');
