import React, { useState } from 'react';

import Input from '../../components/UI/Input/Input';
import * as lang from './lang';
import * as actionTypes from '../../store/actions/actionTypes';

import './ScheduleForm.scss';

import Image from '../../assets/images/common/scheduleForm.png';
import Button from '../../components/UI/Button/Button';
import axios from 'axios';

import { connect } from 'react-redux';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const ScheduleForm = (props) => {
  const [fields, setFields] = useState([
    {
      name: 'name',
      placeholder: lang.FormNameLabel,
      type: 'text',
      value: '',
      error: false,
      validation: (value) => value.length > 0,
    },
    {
      name: 'company',
      placeholder: lang.FormCompanyLabel,
      type: 'text',
      value: '',
      error: false,
      validation: (value) => value.length > 0,
    },
    {
      name: 'email',
      placeholder: lang.FormEmailLabel,
      type: 'email',
      value: '',
      error: false,
      validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
    },
    {
      name: 'phone',
      placeholder: lang.FormPhoneLabel,
      type: 'tel',
      value: '',
      error: false,
      validation: (value) => value.length > 0,
    },
  ]);

  const [message, setMessage] = useState('');

  const handleChanged = (index, value) => {
    const field = { ...fields[index] };
    const validate = !field.validation(value);
    field.error = validate;
    field.value = value;
    const cloneFields = [...fields];
    cloneFields[index] = field;
    setFields(cloneFields);
  };

  const fieldsElements = fields.map((field, index) => (
    <div className='col-12 col-lg-6' key={field.name}>
      <Input
        name={field.name}
        label={field.placeholder}
        error={field.error}
        type={field.type}
        changed={(value) => handleChanged(index, value)}
      />
    </div>
  ));

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const formValid = validateFields();

    if (formValid) {
      sendData();
    }
  };

  const sendData = () => {
    const fd = new FormData();

    for (const field of fields) {
      fd.append(field.name, field.value);
    }

    if (message) {
      fd.append('message', message);
    }

    axios
      .post('/callback/', fd, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          props.openPopup('thankYou');
          setTimeout(() => props.closePopup('thankYou'), 4000);
        }
      })
      .catch((error) => {
        props.openPopup('thankYou');
        setTimeout(() => props.closePopup('thankYou'), 4000);
      });
  };

  const validateFields = () => {
    let valid = true;
    const newFields = [];

    for (const field of fields) {
      const clonedField = { ...field };
      const fieldValidate = clonedField.validation(clonedField.value);
      clonedField.error = !fieldValidate;
      valid = fieldValidate && valid;
      newFields.push(clonedField);
    }
    setFields(newFields);
    return valid;
  };

  return (
    <div className='ScheduleForm'>
      <div className='content'>
        <div className='row align-items-center'>
          <div className='col-lg-6 d-none d-lg-block'>
            <img className='ScheduleForm__image' src={Image} alt='Schedule a meeting' onLoad={props.loaded} />
          </div>
          <div className='col-12 col-lg-6 align-self-center'>
            <div className='row'>
              <div className='col-12 col-lg-10'>
                <div className='caption caption_1'>{lang.ScheduleMeetingHeader}</div>
                <p className='paragraph paragraph_dark'>{lang.ScheduleMeetingDescription}</p>
              </div>
            </div>
            <form className='ScheduleForm__form' onSubmit={onSubmitHandler}>
              <div className='row'>
                {fieldsElements}
                <div className='col-12'>
                  <textarea
                    className='ScheduleForm__message'
                    placeholder={lang.FormMessageLabel}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className='col-10 col-lg-6'>
                  <Button>{lang.FormSubmitButton}</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closePopup: (name) => dispatch({ type: actionTypes.CLOSE_MODAL, modal: name }),
  openPopup: (name) => dispatch({ type: actionTypes.OPEN_MODAL, modal: name }),
});

export default connect(null, mapDispatchToProps)(ScheduleForm);
