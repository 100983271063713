import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TweenMax } from 'gsap';
import './Button.scss';

const Button = (props) => {
  const btn = useRef();
  const circle = useRef();

  useEffect(() => {
    const element = btn.current;
    const mouseHandler = (e) => {
      const position = element.getBoundingClientRect();
      const left = e.clientX - position.left;
      const top = e.clientY - position.top;
      TweenMax.set(circle.current, { left: left, top: top });
    };

    if (props.theme === 'blue') {
      element.addEventListener('mouseover', mouseHandler);
      element.addEventListener('mouseout', mouseHandler);
    }

    return () => {
      if (props.theme === 'blue') {
        element.removeEventListener('mouseover', mouseHandler);
        element.removeEventListener('mouseout', mouseHandler);
      }
    };
  }, [props.theme]);

  let classNames = ['Button'];

  if (props.className) {
    classNames = [...classNames, ...props.className.split(' ')];
  }

  switch (props.theme) {
    case 'blue':
      classNames.push('Button_blue');
      break;

    case 'gray':
      classNames.push('Button_gray');
      break;

    default:
      classNames.push('Button_blue');
  }

  const hoverCircle = props.theme === 'blue' ? <div className='Button__circle' ref={circle}></div> : null;

  return (
    <button className={classNames.join(' ')} onClick={props.clicked} ref={btn}>
      {hoverCircle}
      <span className='Button__text Button__text_normal'>{props.children}</span>
      {/* {props.theme === 'blue' ? <span className='Button__text Button__text_hover'>{props.children}</span> : null} */}
    </button>
  );
};

Button.defaultProps = {
  theme: 'blue',
};

Button.propTypes = {
  theme: PropTypes.string,
  clicked: PropTypes.func,
};

export default Button;
