import React, { useState } from 'react';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';

import { connect } from 'react-redux';

import * as langGetters from './lang';
import * as actionTypes from '../../store/actions/actionTypes';

import './CallbackForm.scss';
import axios from 'axios';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const CallbackForm = (props) => {
  const [fields, setFields] = useState([
    {
      name: 'name',
      placeholder: langGetters.FormNameLabel,
      type: 'text',
      value: '',
      error: false,
      validation: (value) => value.length > 0,
    },
    {
      name: 'company',
      placeholder: langGetters.FormCompanyLabel,
      type: 'text',
      value: '',
      error: false,
      validation: () => true,
    },
    {
      name: 'email',
      placeholder: langGetters.FormEmailLabel,
      type: 'email',
      value: '',
      error: false,
      validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
    },
    {
      name: 'phone',
      placeholder: langGetters.FormPhoneLabel,
      type: 'tel',
      value: '',
      error: false,
      validation: (value) => value.length > 0,
    },
  ]);

  const [message, setMessage] = useState('');

  const [formValid, setFormValid] = useState(true);

  const [formSended, setFormSend] = useState(false);

  const handleChanged = (index, value) => {
    const field = { ...fields[index] };
    const validate = !field.validation(value);
    field.error = validate;
    field.value = value;
    const cloneFields = [...fields];
    cloneFields[index] = field;
    setFields(cloneFields);
  };

  const fieldsElements = fields.map((field, index) => (
    <div className='col-12' key={field.name}>
      <Input
        name={field.name}
        label={field.placeholder}
        error={field.error}
        type={field.type}
        changed={(value) => handleChanged(index, value)}
      />
    </div>
  ));

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const formValid = validateFields();

    if (formValid) {
      sendData();
    }
  };

  const sendData = () => {
    const fd = new FormData();

    for (const field of fields) {
      fd.append(field.name, field.value);
    }

    if (message) {
      fd.append('message', message);
    }

    axios
      .post('/callback/', fd, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          setFormSend(true);
          setTimeout(() => props.closePopup('callback'), 2000);
        }
      })
      .catch((error) => {
        setFormSend(true);
        setTimeout(() => props.closePopup('callback'), 2000);
      });
  };

  const validateFields = () => {
    let valid = true;
    const newFields = [];

    for (const field of fields) {
      const clonedField = { ...field };
      const fieldValidate = clonedField.validation(clonedField.value);
      clonedField.error = !fieldValidate;
      valid = fieldValidate && valid;
      newFields.push(clonedField);
    }
    setFields(newFields);
    setFormValid(valid);
    return valid;
  };

  let formContent = (
    <div className='callback-form'>
      <div className='row'>
        <div className='col-12'>
          <div className='caption caption_1'>{langGetters.CallbackPopupHeader}</div>
        </div>
      </div>
      <form className='ScheduleForm__form' onSubmit={onSubmitHandler}>
        <div className='row'>
          {fieldsElements}
          <div className='col-12'>
            <textarea
              className='ScheduleForm__message'
              placeholder={langGetters.FormMessageLabel}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className='col-12'>
            <Button>{langGetters.FormSubmitButton}</Button>
          </div>
        </div>
      </form>
    </div>
  );

  if (formSended) {
    formContent = (
      <div className='thank-you'>
        <div className='caption caption_1'>{langGetters.ThankYouHeader}</div>
        <p className='paragraph'>{langGetters.ThankYouDescription}</p>
      </div>
    );
  }

  return formContent;
};

const mapDispatchToProps = (dispatch) => ({
  closePopup: (name) => dispatch({ type: actionTypes.CLOSE_MODAL, modal: name }),
});

export default connect(null, mapDispatchToProps)(CallbackForm);
