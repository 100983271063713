import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import parse from 'html-react-parser';

import NotFound from '../../NotFound/NotFound';

import { Link, withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import * as langGetters from '../lang';
import CaseItem from '../../../containers/CasesList/CaseItem/CaseItem';

import './CasesDetail.scss';

const CasesDetail = (props) => {
  const slug = props.match.params.slug;
  const fetchDetail = props.fetchDetail;

  useEffect(() => {
    fetchDetail(slug);
  }, [fetchDetail, slug]);

  const lang = props.match.params.lang;
  const article = props.article;
  const notFound = props.notFound;

  let articleMarkdown = null;
  if (article) {
    const { logo, name, companyName, createdVisible, ideas, objective, body } = article;
    const otherNews = props.others ? props.others.map((item) => <CaseItem key={item.id} {...item} />) : null;
    articleMarkdown = (
      <div className='CaseDetail'>
        <div className='Case-header'>
          <div className='content'>
            <Link to={`/${lang}/cases`}>
              <div className='Case-header__back'>
                <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M6.00003 0.999972L2 5L6.00003 9.00003' stroke='#017BE5' strokeWidth='2' />
                </svg>
                {langGetters.CaseDetailBackButton}
              </div>
            </Link>
            <div className='row'>
              <div className='col-12 col-lg-5'>
                <div className='Case-header__logo' style={{ backgroundImage: `url(${logo})` }}></div>
                <div className='d-lg-none'>
                  <div className='Case-header__option'>
                    <span>{langGetters.CaseDetailCompanyLabel}</span> {companyName}
                  </div>
                  <div className='Case-header__option'>
                    <span>{langGetters.CaseDetailDateLabel}</span> {moment(createdVisible).format('DD MMMM YYYY')}
                  </div>
                </div>
              </div>
              <div className='Case-header__name-column col-lg-6 offset-lg-1'>
                <h1 className='caption caption_1 Case-header__name'>{name}</h1>
                <div className='d-none d-lg-block'>
                  <div className='Case-header__option'>
                    <span>{langGetters.CaseDetailCompanyLabel}</span> {companyName}
                  </div>
                  <div className='Case-header__option'>
                    <span>{langGetters.CaseDetailDateLabel}</span> {moment(createdVisible).format('DD MMMM YYYY')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='CaseDetail__body article-body'>
          <div className='content'>
            <div className='row CaseDetail__objective'>
              <div className='col-12 col-lg-2 offset-lg-2'>
                <h4>{langGetters.CaseDetailOjbectiveLabel}</h4>
              </div>
              <div className='col-12 col-lg-5 offset-lg-1'>{parse(objective)}</div>
            </div>
            <div className='row CaseDetail__objective'>
              <div className='col-12 col-lg-2 offset-lg-2'>
                <h4>{langGetters.CaseDetailIdeaLabel}</h4>
              </div>
              <div className='col-12 col-lg-5 offset-lg-1'>{parse(ideas)}</div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-8 offset-lg-2'>{parse(body)}</div>
            </div>
          </div>
        </div>
        <div className='NewsDetail__others article-others'>
          <div className='content'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-6'>
                <h2 className='caption caption_1'>{langGetters.CaseDetailOtherPublications}</h2>
              </div>
              <div className='col-3 offset-3 d-none d-lg-block'>
                <Link to={`/${lang}/news`}>
                  <div className='Case-header__back Case-header__back_right'>
                    {langGetters.CaseDetailOtherPublications}
                    <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M0.999972 0.999972L5 5L0.999972 9.00003' stroke='#017BE5' strokeWidth='2' />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
            <div className='row'>{otherNews}</div>
            <div className='col-12 col-lg-3 offset-lg-3 d-lg-none'>
              <Link to={`/${lang}/news`}>
                <div className='Case-header__back Case-header__back_right'>
                  {langGetters.CaseDetailAllPublications}
                  <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M0.999972 0.999972L5 5L0.999972 9.00003' stroke='#017BE5' strokeWidth='2' />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (notFound) articleMarkdown = <NotFound />;

  return articleMarkdown;
};

const mapStateToProps = (state) => ({
  ...state.cases.detail,
  notFound: state.cases.notFound,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDetail: (slug) => dispatch(actions.fetchCasesDetail(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CasesDetail));
