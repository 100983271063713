import React from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy = (props) => {
  return (
    <div className='PrivacyPolicy'>
      <div className='content'>
        <div className='row'>
          <div className='col-lg-10 col-12'>
            <h1 className='caption caption_1'>Privacy Policy</h1>
            <p className="paragraph paragraph_large">
              Your privacy is important to us. It is BestPlace's policy to respect your privacy regarding any
              information we may collect from you across our website, <a href="https://bestplace.ai">https://bestplace.ai</a>,
              and other sites we own and operate.
            </p>
            <p className="paragraph paragraph_large">
              We only ask for personal information when we truly need it to provide a service to you. We collect it by
              fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and
              how it will be used.
            </p>
            <p className="paragraph paragraph_large">
              We only retain collected information for as long as necessary to provide you with your requested service.
              What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well
              as unauthorised access, disclosure, copying, use or modification.
            </p>
            <p className="paragraph paragraph_large">
              We don't share any personally identifying information publicly or with third-parties, except when required
              to by law.
            </p>
            <p className="paragraph paragraph_large">
              Our website may link to external sites that are not operated by us. Please be aware that we have no
              control over the content and practices of these sites, and cannot accept responsibility or liability for
              their respective privacy policies.
            </p>
            <p className="paragraph paragraph_large">
              You are free to refuse our request for your personal information, with the understanding that we may be
              unable to provide you with some of your desired services.
            </p>
            <p className="paragraph paragraph_large">
              Your continued use of our website will be regarded as acceptance of our practices around privacy and
              personal information. If you have any questions about how we handle user data and personal information,
              feel free to contact us.
            </p>
            <p className="paragraph paragraph_large">This policy is effective as of 08 April 2022.</p>
            <p className="paragraph paragraph_large">BestPlace Terms of Service</p>
            <h2 className="caption caption_1">1. Terms</h2>
            <p className="paragraph paragraph_large">
              By accessing the website at <a href="https://bestplace.ai">https://bestplace.ai</a>, you are agreeing to be bound by these terms of
              service, all applicable laws and regulations, and agree that you are responsible for compliance with any
              applicable local laws. If you do not agree with any of these terms, you are prohibited from using or
              accessing this site. The materials contained in this website are protected by applicable copyright and
              trademark law.
            </p>
            <h2 className="caption caption_1">2. Use license</h2>
            <p className="paragraph paragraph_large">
              Permission is granted to temporarily download one copy of the materials (information or software) on
              BestPlace's website for personal, non-commercial transitory viewing only. This is the grant of a license,
              not a transfer of title, and under this license you may not:<br />
              <ul>
                <li>• modify or copy the materials;</li>
                <li>
                  • use the materials for any commercial purpose, or for any public display (commercial or
                  non-commercial);
                </li>
                <li>• attempt to decompile or reverse engineer any software contained on BestPlace's website;</li>
                <li>• remove any copyright or other proprietary notations from the materials; or</li>
                <li>• transfer the materials to another person or "mirror" the materials on any other server.</li>
              </ul>
              This license shall automatically terminate if you violate any of these restrictions and may be terminated
              by BestPlace at any time. Upon terminating your viewing of these materials or upon the termination of this
              license, you must destroy any downloaded materials in your possession whether in electronic or printed
              format.
            </p>
            <h2 className="caption caption_1">3. Disclaimer</h2>
            <p className="paragraph paragraph_large">
              The materials on BestPlace's website are provided on an 'as is' basis. BestPlace makes no warranties,
              expressed or implied, and hereby disclaims and negates all other warranties including, without limitation,
              implied warranties or conditions of merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of rights.
            </p>
            <p className="paragraph paragraph_large">
              Further, BestPlace does not warrant or make any representations concerning the accuracy, likely results,
              or reliability of the use of the materials on its website or otherwise relating to such materials or on
              any sites linked to this site.
            </p>
            <h2 className="caption caption_1">4. Limitations</h2>
            <p className="paragraph paragraph_large">
              In no event shall BestPlace or its suppliers be liable for any damages (including, without limitation,
              damages for loss of data or profit, or due to business interruption) arising out of the use or inability
              to use the materials on BestPlace's website, even if BestPlace or a BestPlace authorized representative
              has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do
              not allow limitations on implied warranties, or limitations of liability for consequential or incidental
              damages, these limitations may not apply to you.
            </p>
            <h2 className="caption caption_1">5. Accuracy of materials</h2>
            <p className="paragraph paragraph_large">
              The materials appearing on BestPlace's website could include technical, typographical, or photographic
              errors. BestPlace does not warrant that any of the materials on its website are accurate, complete or
              current. BestPlace may make changes to the materials contained on its website at any time without notice.
              However BestPlace does not make any commitment to update the materials.
            </p>
            <h2 className="caption caption_1">6. Links</h2>
            <p className="paragraph paragraph_large">
              BestPlace has not reviewed all of the sites linked to its website and is not responsible for the contents
              of any such linked site. The inclusion of any link does not imply endorsement by BestPlace of the site.
              Use of any such linked website is at the user's own risk.
            </p>
            <h2 className="caption caption_1">7. Modifications</h2>
            <p className="paragraph paragraph_large">
              BestPlace may revise these terms of service for its website at any time without notice. By using this
              website you are agreeing to be bound by the then current version of these terms of service.
            </p>
            <h2 className="caption caption_1">8. Governing Law</h2>
            <p className="paragraph paragraph_large">
              These terms and conditions are governed by and construed in accordance with the laws of Europe and you
              irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
