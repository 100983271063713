import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../../components/UI/Button/Button';

import * as lang from './lang';

import './NotFound.scss';
import NotFoundImage from '../../assets/images/common/404.png';

const NotFound = (props) => {
  const currentLanguage = props.match.params.lang;

  const onClickButtonHandler = (e) => {
    e.preventDefault();
    props.history.push('/' + currentLanguage + '/');
  };

  return (
    <div className='NotFound'>
      <div className='content'>
        <div className='NotFound__container'>
          <div className='row'>
            <div className='col-12 col-lg-5'>
              <img src={NotFoundImage} alt='Page not found' />
            </div>
            <div className='col-lg-4 offset-lg-1 col-12'>
              <div className='caption caption_1'>{lang.NotFoundHeader}</div>
              <p className='paragraph'>{lang.NotFoundDescription}</p>
              <div className='row'>
                <div className='col-8'>
                  <Button clicked={onClickButtonHandler}>{lang.NotFoundMainButtonTitle}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NotFound);
