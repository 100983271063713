import { useEffect } from 'react';
import { Switch, Route } from 'react-router';
import AboutUs from './AboutUs/AboutUs';
import CpgPage from './CpgPage/CpgPage';
import RetailPage from './RetailPage/RetailPage';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import AppPrivacyPolicy from './PrivacyPolicy/AppPrivacyPolicy';
import News from './News/News';
import NewsDetail from './NewsDetail/NewsDetail';
import Cases from './Cases/Cases';
import CasesDetail from './Cases/CasesDetail/CasesDetail';
import NotFound from './NotFound/NotFound';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions/language';
import getCookie from '../assets/js/getCookie';

const Pages = (props) => {
  useEffect(() => {
    const localLanguage = getCookie('SITE_LANG');
    if (!localLanguage || localLanguage !== props.match.params.lang) {
      const currentParam = props.match.params.lang;
      let langParam = currentParam;
      if (!props.languages.find((lang) => lang.value === currentParam)) {
        langParam = props.activeLanguage.value;
      }

      const expire = new Date(new Date().setFullYear(new Date().getFullYear() + 5));

      document.cookie = `SITE_LANG=${langParam}; path=/; expires=${expire.toUTCString()}`;
      const pathName = props.location.pathname;
      const pathArray = pathName.split('/');
      pathArray[1] = langParam;
      window.location.replace(pathArray.join('/'));
    }
  }, [props]);
  return (
    <Switch>
      <Route path='/:lang/retail' exact component={RetailPage} />
      <Route path='/:lang/about-us' exact component={AboutUs} />
      <Route path='/:lang/privacy-policy' exact component={PrivacyPolicy} />
      <Route path='/:lang/app/privacy-policy' exact component={AppPrivacyPolicy} />
      {props.activeLanguage === 'ru' && <Route path='/:lang/blog' exact component={News} />}
      {props.activeLanguage === 'ru' && <Route path='/:lang/blog/:slug/' exact component={NewsDetail} />}
      {/* <Route path='/:lang/cases' exact component={Cases} /> */}
      {/* <Route path='/:lang/cases/:slug/' exact component={CasesDetail} /> */}
      <Route path='/:lang/' exact component={CpgPage} />
      <Route component={NotFound} />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    languages: state.language.items,
    activeLanguage: state.language.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguageBackend: () => dispatch(actionCreators.getLanguage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
