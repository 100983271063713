import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import languageReducer from './store/reducers/language';
import modalsReducer from './store/reducers/modals';
import newsReducer from './store/reducers/news';
import casesReducer from './store/reducers/cases';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const rootReducer = combineReducers({
  language: languageReducer,
  modals: modalsReducer,
  news: newsReducer,
  cases: casesReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
